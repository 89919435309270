import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';


export const Filters = ({ changeSelectFilter, filtered, searchNameOrAdviser }) => {

    const [filterListBy, setFilterListBy] = useState('');



    const handleChange = async (e) => { setFilterListBy(e.target.value) }

    const listFilter = [
        {
            id: 1,
            prospect: 'PRE-CANDIDATO',
            name:"PRE-CANDIDATO"
        },
        {
            id: 10,
            prospect: 'OBSERVADO',
            name: 'OBSERVADO'
        },
        {
            id: 11,
            prospect: 'CONTACTADO',
            name: 'CONTACTADO'
        }, {
            id: 2,
            prospect: 'EQUIPO COMERCIAL',
            name: 'EQUIPO COMERCIAL'
        }, {
            id: 3,
            prospect: 'SOPORTE COMERCIAL',
            name: 'SOPORTE COMERCIAL',
        }, {
            id: 4,
            prospect: 'COMPLIANCE',
            name: 'COMPLIANCE',
        }, {
            id: 5,
            prospect: 'PENDIENTE ENVIO CONTRATO',
            name: 'PENDIENTE ENVIO CONTRATO'
        }, {
            id: 6,
            prospect: 'PENDIENTE FIRMA',
            name: 'PENDIENTE FIRMA'
        }, {
            id: 7,
            prospect: 'CONTRATO FIRMADO',
            name: 'CONTRATO FIRMADO'
        },
        {
            id: 8,
            prospect: 'CONTRATO RECHAZADO',
            name: 'CONTRATO RECHAZADO '
        },
        {
            id: 10,
            prospect: 'CONTRATO ANULADO',
            name: 'CONTRATO ANULADO '
        },
        {
            id: 9,
            prospect: 'RECHAZADO',
            name: 'USUARIO RECHAZADO'
        }
        

    ]

    return (
        <div className="row" id="container_filters">
            <div className="col-md-6" id="container_filter_by">
                <span className="label_filter blue_title">Filtrar por</span>
                <select name="select_filter_by" id="select_filter_by" value={ filtered } onChange={(e)=> changeSelectFilter(e) }>
                    <option value=""> Todos los candidatos por Estado</option>
                    {listFilter.map((option, index) => {
                        return (
                            <option key={index} value={option.prospect} className="blue_data">
                                {option.name}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className="col-md-6" id="conent_filter_list_by">
                <input type="text" value={ filterListBy } name="input_filter_by" id="input_filter_by" placeholder="Filtrar el listado por..." onChange={(e) => handleChange(e) } />
                <div style={{margin: "0 10px", cursor:"pointer"}} onMouseDown={() => searchNameOrAdviser(filterListBy)}>
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>
        </div>
    )
}
